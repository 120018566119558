<template>
  <div class="bg">
    <img src="../assets/indexj.jpg" alt="" class="bgimg" />
    <div class="login_box">
      <div class="avatarbox">
        <div class="avatarleft">
          <img src="../assets/index.png" alt="" />
        </div>
        <p class="avatartitle">APP<br />后台管理系统</p>
      </div>
      <el-form :model="form" :rules="rules2" ref="form" class="login_form">
        <el-form-item prop="account">
          <el-input
            prefix-icon="el-icon-user-solid"
            placeholder="请输入账号"
            v-model="form.account"
          ></el-input>
        </el-form-item>
        <el-form-item prop="pwd">
          <el-input
            prefix-icon="el-icon-lock"
            placeholder="请输入密码"
            v-model="form.pwd"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-on:click="login" style="width: 80%"
            >登 录</el-button
          >
          <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
var connection = "";
export default {
  data() {
    return {
      dialogVisible: true,
      rules2: {
        account: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      form: {
        account: "",
        pwd: "",
      },
      sid: "",
      loginbg: require("../assets/indexj.jpg"),
    };
  },
  mounted() {
    // connection = new signalR.HubConnectionBuilder()
    //   .withUrl(this.serveurl.serve+"/AdminChat")
    //   .build();
    // setInterval(function () {
    //   console.log(this.connection.connectionState);
    // }, 1000);
  },
  methods: {
    login() {
      let adminmenus = [
        {
          name: "统计",
          img: "el-icon-s-data",
          children: [
            { name: "用户统计", url: "userStatic" },
            // { name: "拍卖统计", url: "auctionStatic" },
          ],
        },
        {
          name: "医患连线",
          img: "el-icon-connection",
          children: [
            { name: "连线列表", url: "backvideo" },
            // { name: "帖子管理", url: "postManage" },
          ],
        },
        {
          name: "关系管理",
          img: "el-icon-link",
          children: [{ name: "绑定关系", url: "bindingRelation" }],
        },
        {
          name: "医患管理",
          img: "el-icon-s-custom",
          children: [
            { name: "医生信息", url: "doctorManage" },
            { name: "医生管理", url: "setDoctor" },
            { name: "所有患者", url: "patientManage" },
            { name: "报名患者", url: "signUpPatManage" },
            { name: "紧急患者管理", url: "setEmergency" },
            { name: "自建患者", url: "selfBuiltPatient" },
          ],
        },
        {
          name: "科室管理",
          img: "el-icon-s-home",
          children: [{ name: "科室管理", url: "hDManage" }],
        },
        {
          name: "内容管理",
          img: "el-icon-s-management",
          children: [
            { name: "医生科普", url: "scienceArticle" },
            { name: "资质文章", url: "seniorityArticle" },
            { name: "文章管理", url: "articleManage" },
            { name: "轮播图管理", url: "pictureManage" },
            { name: "今日推荐", url: "recommendToday" },
            { name: "精选推荐", url: "selectRecommend" },
            { name: "小视频", url: "smallVideo" },
            { name: "状态说明管理", url: "statusMsgManage" },
            { name: "视频管理", url: "userUpload" },
            // { name: "用户上传视频", url: "userVideoList" },
            // { name: "用户上传文章", url: "userArticleList" },
            // { name: "寻医视频", url: "xyvideolist" },
            // { name: "视频管理", url: "SearchingVideo" },
            { name: "标签管理", url: "labelManagement" },
            { name: "视频审核", url: "userUpload" },
          ],
        },
        {
          name: "留言管理",
          img: "el-icon-s-claim",
          children: [
            { name: "患者留言管理", url: "leaveMsgManage" },
            { name: "医生留言管理", url: "leaveMsgManageDoc" },
          ],
        },
        // {
        //     name: "导诊管理",
        //     icon: "el-icon-s-promotion",
        //     submenu: [
        //         { name: "导诊管理", url: "guidanceManage" },
        //     ]
        // },
        {
          name: "疾病管理",
          img: "el-icon-s-order",
          children: [
            { name: "疾病管理", url: "illnessManage" },
            { name: "热门疾病", url: "illLibIndex" },
          ],
        },
        {
          name: "处方管理",
          img: "el-icon-collection",
          children: [{ name: "药品管理", url: "drugManage" }],
        },
        {
          name: "诊单管理",
          img: "el-icon-files",
          children: [
            { name: "寻医列表", url: "XYOrderManage" },
            { name: "诊单列表", url: "medicalOrderManage" },
            { name: "反馈列表", url: "FKOrderManage" },
            { name: "分配寻医表", url: "distributionXYOrder" },
            { name: "诊单标记", url: "diagnosisSheetMark" },
          ],
        },
        {
          name: "社区管理",
          img: "el-icon-s-flag",
          children: [
            { name: "模块管理", url: "addSection" },
            { name: "帖子管理", url: "postManage" },
          ],
        },
        {
          name: "消息管理",
          img: "el-icon-s-comment",
          children: [
            { name: "系统通知", url: "noticeManage" },
            { name: "APP推送", url: "appPush" },
          ],
        },
        {
          name: "账号管理",
          img: "el-icon-s-check",
          children: [
            { name: "添加账号", url: "powerManage" },
            { name: "角色管理", url: "bindRelationship" },
          ],
        },
        {
          name: "聊天管理",
          img: "el-icon-headset",
          children: [
            // { name: "聊天", url: "talk"},
            { name: "查询所属客服", url: "searchservice" },
            { name: "修改服务人", url: "changeservice" },
            { name: "查看聊天记录", url: "talklook" },
            { name: "聊天监听", url: "talkmonitor" },
            { name: "查询转移记录", url: "queryTransferRecords" },
            { name: "超时未联系", url: "timeoutNoContacted" },
            { name: "设置欢迎词", url: "userWelcomingsSpeech" },
          ],
        },
        {
          name: "直播管理",
          img: "el-icon-video-camera",
          children: [
            { name: "直播管理", url: "liveManage" },
            { name: "举报列表", url: "reportlist" },
            { name: "直播预告", url: "LivePreview" },
          ],
        },
        {
          name: "方案管理",
          img: "el-icon-s-opportunity",
          children: [
            { name: "审核方案", url: "planManage" },
            { name: "反馈审核方案", url: "FKplanmange" },
          ],
        },
        {
          name: "订单管理",
          img: "el-icon-tickets",
          children: [
            { name: "处方订单审核", url: "orderReview" },
            { name: "客服诊金", url: "exportYFOrder" },
            { name: "欧西思订单", url: "exportOXSOrder" },
            { name: "商城订单", url: "mallOrder" },
            { name: "对账报表", url: "exportMallOrder" },
            { name: "处理异常订单", url: "handleAbnormalOrder" },
          ],
        },
        {
          name: "退款管理",
          img: "el-icon-s-finance",
          children: [
            { name: "客服诊金退款", url: "applyRefund" },
            { name: "欧西思退款", url: "applyOXSRefund" },
            // { name: "处方退款", url: "orderReviewtk" },
          ],
        },
        {
          name: "拍卖监控",
          img: "el-icon-view",
          children: [{ name: "分组列表", url: "groupAuction" }],
        },
        {
          name: "报表管理",
          img: "el-icon-data-line",
          children: [
            { name: "竞得用户详情", url: "reportform1", img: "1" },
            { name: "用户推荐汇总", url: "reportform1", img: "2" },
            { name: "拍卖汇总统计", url: "reportform1", img: "3" },
            { name: "其他商品被推荐人订单", url: "reportform1", img: "4" },
          ],
        },
      ];
      // let list = [];
      this.axios
        .post("/gu/gu_admin_login", this.qs.stringify(this.form))
        .then((rs) => {
          // console.log(rs)
          if (rs.data.code == 0) {
            this.$message({
              message: "登录成功",
              type: "success",
            });
            let arr = rs.data.right;
            this.sid = rs.data.result.id;
            sessionStorage.setItem("islogin", "yes");
            sessionStorage.setItem("name", rs.data.result.account);
            sessionStorage.setItem("cust_id", rs.data.result.cust_id);
            sessionStorage.setItem("servicer_id", rs.data.result.servicer_id);
            sessionStorage.setItem("headimg", rs.data.result.headimg);
            sessionStorage.setItem("pwd", rs.data.result.pwd);
            sessionStorage.setItem("stype", rs.data.result.is_chat);
            this.$store.commit("getusername", rs.data.result.account);
            sessionStorage.setItem("id", rs.data.result.id);
            sessionStorage.setItem("level", rs.data.result.level);
            this.$store.commit("setList", rs.data.right);
            localStorage.setItem("usertalklist" + this.sid, JSON.stringify([]));
            if (this.form.account == "test" && arr == false) {
              sessionStorage.setItem("right", JSON.stringify(adminmenus));
              this.$store.commit("setmenu", adminmenus);
              this.$router.push("/" + adminmenus[0].children[0].url);
            } else {
              sessionStorage.setItem("right", JSON.stringify(rs.data.right));
              this.$store.commit("setmenu", arr);
              this.$router.push("/" + arr[0].children[0].url);
            }
            this.axios
              .post(
                "/log/chat_login_log",
                this.qs.stringify({
                  data:
                    "【登录】 " +
                    rs.data.result.id +
                    "  ---   " +
                    rs.data.result.account,
                })
              )
              .then((res) => {});

            var _this = this;
            this.connection
              .start()
              .then(function () {
                _this.connection.invoke("login", rs.data.result.account);
              })
              .catch((error) => {
                _this.connection.invoke("login", rs.data.result.account);
              });

            this.connection.on("logined", function (ip) {
              _this.connection.stop();
              // _this.$router.replace("/");
              _this.axios
                .post(
                  "/log/chat_login_log",
                  _this.qs.stringify({
                    data:
                      "【退出】 " +
                      sessionStorage.getItem("id") +
                      "  ---   " +
                      sessionStorage.getItem("name"),
                  })
                )
                .then((res) => {});
              _this.$alert("你的账户在IP：" + ip + "上登录了", "警告", {
                confirmButtonText: "确定",
                callback: (action) => {
                  localStorage.clear();
                  sessionStorage.clear();
                  _this.connection.stop();
                  _this.$router.replace("/");
                },
              });
            });
          } else {
            this.$message.error("账号密码错误，请重新输入");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.bg {
  position: relative;
  width: 100%;
  height: 100%;
}
.bgimg {
  width: 100%;
  height: auto;
}
.login_bg {
  background: #000;
}

.title {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}

.login_box {
  width: 500px;
  height: 300px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

.el-input {
  width: 80%;
}

.el-input__inner {
  width: 100%;
}

.rememberme {
  float: left;
}

.avatarbox {
  height: 100%;
  width: 200px;
  float: left;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top: 66px;
  box-sizing: border-box;
}
.avatarleft {
  width: 88px;
  height: 88px;
  border: 1px solid #226fef;
  border-radius: 50%;
  padding: 3px;
  margin: 0 auto;
  box-sizing: border-box;
}
.avatartitle {
  color: #226fef;
  font-size: 25px;
  font-weight: 600;
  margin-top: 15px;
  font-family: "楷体";
}
.avatarbox img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.login_form {
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  float: right;
  background-color: #fff;
  padding-top: 72px;
}
.login_form .el-form-item__error {
  left: 40px;
}
</style>
